.header-main-contain{
    margin-top: 20px;
    background-color: white;
    @include row;
    justify-content: space-between;
    margin-bottom: 5px;
    .imgs-contain{
        @include row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        img,
        .logo-confia{
            margin: 0;
            width: 146px;
            padding: 8px 5px;
            cursor: pointer;
        }
    }
    
    .header-nav{
        display: flex;
        align-items: flex-end;
        .nav-list{
            margin: 0;
            li{
                margin: 0;
                padding: 0;
            }
            padding: 0;
            @include row;
            list-style: none;
            justify-content: space-between;
            .activeRouteControlPanel,
            .activeRoute{
                background-color: #ededed;
            }
            .nav-item{
                color: #434343;
                text-decoration: none;
                border: solid 1.5px $primary;
                border-bottom: none;
                padding: 15px 8px;
                margin: 26px 5px 0;
                text-transform: uppercase;
                font-family: $secondFont;
                font-size: 12px;
                margin-top: 2px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.096);
                }
                &:visited {
                    color: #434343;
                }
            }
        } 
    } 
    mat-divider{
        border-bottom: 2px solid $primary;
        border-top: none;
        width: 100%;
        margin: 0px auto;
    }
    
}

@media (max-width:860px){
    .header-main-contain{
        flex-direction: column;
        width: 100%;
        mat-divider{
            width: 95%;
        }
        .header-nav{
            .nav-list{
                flex-direction: column; 
                width: 100%;
                margin: 10px auto;
                .nav-item{
                 width: 90%;
                 border-bottom: solid 1.5px $primary;
                 margin: 3px auto;
                 text-decoration: none;
                 color: black;
                 height: 20px;
                 padding: 6px;
                }
             }
            
        }
    }
}
