.basic_form{
    width: 350px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

  .borrower_form {
    width: 85%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3{
        margin-top: 30px;
    }
    .cotitular_container,
    .jobData_container,
    .residenceData_container,
    .balance_container,
    .personal_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .mat-form-field{
        ::ng-deep.mat-form-field-underline {
            /*change color of underline*/
            background-color: $primary;
            font-size: 10px;
          } 
      }
      .birth_date{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -7px;
      }
    }
    .button_container{
        width: 80%;
        display: flex;
        align-items: center;
      }
  

    mat-form-field {
      margin: 15px;
    }
    
    button {

      margin: 15px auto;
      width: 80%;
    }
    h3 {
      font-size: 24px;
      color: rgb(62, 62, 62);
      font-weight: 400;
      span {
        font-size: 16px;
        color: rgb(135, 133, 133);
        margin-left: 5px;
      }
    }
    h4 {
        margin-left: 15px;
      color: rgb(62, 62, 62);
    }
  }


@media (max-width: 800px) {
    .borrower_form{
        margin: 15px 5px;
        h3{
            margin-left: 15px;
        }
    .cotitular_container,
    .jobData_container,
    .residenceData_container,
    .balance_container,
    .personal_container{
        text-align: left;
        width: 100%;
        div{
           width: 100%;
           .mat-select-arrow,
           .mat-select-arrow-wrapper{
            width: 0;
          }
        }
      .date-content{
          .mat-form-field-suffix{
            flex:auto;
          }
        }
        input,
        mat-form-field {
           width: 100%;
          }
    }
    }
}