/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import './0-base/base_dir';
@import './1-components/base_dir';

html, body { height: 100%; }
body {
    margin: 0;
    width: 100%;
/*     min-height: calc(100vh - 200.56px); */
}
*{
    font-family: $font;
}

mat-icon{
    font-family: 'Material Icons' !important;
}
.mat-tab-labels{
    justify-content: center !important;
}



html.cdk-global-scrollblock {
    position: initial !important;
}
  
html.cdk-global-scrollblock body {
    position: fixed;
}

