
// Import material theming functions
@import '../../theme.scss';

//Colors
$primary: mat-color($my-custom-primary);
$secondary: mat-color($my-custom-accent);
$warn: mat-color($my-custom-warn);
$white: white;
$darkblue: #001b28;


//Responsive
$tablet: 857px;
$desktop: 992px;
$large: 1200px;
$mobile:767px;
